import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'

import Layout from '../../components/layout'

import stilettoDoc from '../../images/stiletto1.jpg'

import '../../styles/common-styles.css'
import '../../styles/music-styles.css'
import '../../styles/layout-styles.css'
import '../../styles/album-styles.css'

const StilettoSeverinsenPage = () => {
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  return (
    <Layout>
      <div className='pageTitle'>
        Stiletto Brass Quintet with Doc Severinsen
      </div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      {!isMobile && (
        <div className='albumInfoWrapper'>
          <img className='albumInfoImage' src={stilettoDoc} alt='' />
          <div className='albumInfoRight'>
            <div className='albumInfoNotes text-gray-700'>
              <p className='my-4'>
                Stiletto Brass Quintet, formed in 2010, has gained exposure as a
                leading ensemble with performances that the audience greeted
                with rousing ovations with Doc Severinsen and the Heartland
                Festival Orchestra, at the International Women's Brass
                Conference, the International Trumpet Guild Conference, The
                National Trumpet Competition, the Great American Brass Band
                Festival and concerts in New York, New Hampshire, Illinois,
                Tennessee, Pennsylvania, West Virginia, Ohio, and Oregon.
                Stiletto features five highly accomplished women from the U.S.
                with careers spanning the fields of orchestral, wind band, and
                brass chamber music: Amy Gilreath and Susan Rider, trumpets;
                Misty Tolle, horn; Natalie Mannix, trombone; and Velvet Brown,
                tuba.
              </p>
              <p className='my-4'>
                "The Stiletto Brass Quintet is a group of five superb musicians
                whose technique and ensemble work is simply unmatched.
                Joyousness, melodic introspection, tenderness, excitement---the
                full range of emotions is in every note they play. The fact that
                they are beautiful women in red stilettos…well that’s just icing
                on the cake. If they give the impression of owning the stage,
                it’s because they do. I’ve seen a lot of brass quintets in my
                day. But the Stiletto Brass Quintet is truly a breath of fresh
                air and a wonderful evening’s entertainment." --Doc Severinsen
              </p>
              <p className='my-4'>
                <a
                  href='https://www.amazon.com/Stiletto-Brass-Quintet-Doc-Severinsen/dp/B00CJMRNKW'
                  target='_blank'
                  rel='noreferrer'
                >
                  Stiletto Brass Quintet with Doc Severinsen can be purchased
                  here
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='mobileAlbumInfoWrapper'>
          <img className='mobileAlbumInfoImage' src={stilettoDoc} alt='' />
          <div className='content text-gray-700'>
            <p className='my-4'>
              Stiletto Brass Quintet, formed in 2010, has gained exposure as a
              leading ensemble with performances that the audience greeted with
              rousing ovations with Doc Severinsen and the Heartland Festival
              Orchestra, at the International Women's Brass Conference, the
              International Trumpet Guild Conference, The National Trumpet
              Competition, the Great American Brass Band Festival and concerts
              in New York, New Hampshire, Illinois, Tennessee, Pennsylvania,
              West Virginia, Ohio, and Oregon. Stiletto features five highly
              accomplished women from the U.S. with careers spanning the fields
              of orchestral, wind band, and brass chamber music: Amy Gilreath
              and Susan Rider, trumpets; Misty Tolle, horn; Natalie Mannix,
              trombone; and Velvet Brown, tuba.
            </p>
            <p className='my-4'>
              "The Stiletto Brass Quintet is a group of five superb musicians
              whose technique and ensemble work is simply unmatched. Joyousness,
              melodic introspection, tenderness, excitement---the full range of
              emotions is in every note they play. The fact that they are
              beautiful women in red stilettos…well that’s just icing on the
              cake. If they give the impression of owning the stage, it’s
              because they do. I’ve seen a lot of brass quintets in my day. But
              the Stiletto Brass Quintet is truly a breath of fresh air and a
              wonderful evening’s entertainment." --Doc Severinsen
            </p>
            <p className='my-4'>
              <a
                href='https://www.amazon.com/Stiletto-Brass-Quintet-Doc-Severinsen/dp/B00CJMRNKW'
                target='_blank'
                rel='noreferrer'
              >
                Stiletto Brass Quintet with Doc Severinsen can be purchased here
              </a>
            </p>
          </div>
        </div>
      )}
      <div
        style={{ marginLeft: '15%', marginTop: '35px', marginBottom: '35px' }}
      >
        <Link to='/music'>←Back to Discography</Link>
      </div>
    </Layout>
  )
}

export default StilettoSeverinsenPage
